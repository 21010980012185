<template>
  <div>
    <Header>充值</Header>
    <div class="top_up">充值方式</div>
    <div class="top_icon">
      <div class="way">
        <div><img src="../../../../static/image/icon.png" alt="" /></div>
        <div class="way_text">支付宝</div>
      </div>
      <div>
        <van-checkbox v-model="checked"></van-checkbox>
      </div>
    </div>

    <div class="shuoming">*平台钱包单笔最高充值金额500元！</div>
    <div class="shuoming">如您需充值更多资金，请您使用银企钱包！</div>
    <!-- <div class="top_icon">
      <div class="way">
        <div><img src="../../../../static/image/icon@2x.png" alt="" /></div>
        <div class="way_text">银行卡</div>
      </div>
      <div>
        <input type="radio" />
      </div>
    </div> -->
    <div class="showdow"></div>
    <div class="mon_text">充值金额</div>
    <div class="mon" v-if="monyList.active === 4">
      <div class="symbol">￥</div>
      <input
        class="num"
        v-model="mony"
        @input="inputMony($event)"
        placeholder="请输入充值金额"
        type="number"
      />
    </div>
    <div class="monyList">
      <div
        class="monyBox"
        v-for="(item, key) in monyList.list"
        :key="item"
        :class="key === monyList.active ? 'active' : null"
        @click="changeMonyList(key)"
      >
        <div class="mony" v-if="key === monyList.list.length - 1">
          {{ item }}
        </div>
        <div class="mony" v-else>¥ {{ item }}</div>
      </div>
      <!-- <div class="monyBox">其他金额</div> -->
    </div>
    <div
      class="but"
      :class="checked && mony ? 'active' : null"
      @click="payChannel"
    >
      充值
    </div>
    <div v-html="payInfo"></div>
  </div>
</template>
<script>
import { isSafari } from '../../../utils/tools';
export default {
  data() {
    return {
      mony: '',
      checked: true,
      pay_channel: '', // 选择充值方式
      orderNo: '',
      isShowPay: false,
      payInfo: '',
      monyList: {
        list: ['5', '50', '100', '500', '其他金额'],
        // list: ['5', '50', '100', '500', '1000', '2000', '5000', '其他金额'],
        active: 0,
      },
    };
  },
  methods: {
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log('页面被挂起');
      } else {
        console.log('页面呼出');
        // 页面呼出 支付宝购买后返回，刷新次数接口
        const query = {
          token: localStorage.getItem('token'),
          order_no: this.orderNo,
        };
        if (!this.orderNo) return;
        this.$api.loop(query).then((e) => {
          if (e.code != 100000) return this.$toast(e.msg);
          this.info = JSON.stringify(e.data);
          if (e.data.status === 2) {
            // this.$router.replace('/Allorder?type=2');
            this.$router.back();
            return this.$toast('支付成功');
          } else {
            return this.$toast('支付失败');
          }
        });
        // const paying = localStorage.getItem('paying');
        // if (paying && paying != 'undefined') {
        //   localStorage.removeItem('paying');
        //   const query = {
        //     token: localStorage.getItem('token'),
        //     order_no: paying,
        //   };
        //   this.$api.loop(query).then((e) => {
        //     if (e.code != 100000) return this.$toast(e.msg);
        //     this.info = JSON.stringify(e.data);
        //     if (e.data.status === 2) {
        //       // this.$router.replace('/Allorder?type=2');
        //       this.$router.back();
        //       return this.$toast('支付成功');
        //     } else {
        //       return this.$toast('支付失败');
        //     }
        //   });
        // }
      }
    },
    changeMonyList(key) {
      this.monyList.active = key;
      if (key !== 7) {
        this.mony = this.monyList.list[key];
      } else {
        this.mony = '';
      }
    },
    ///返回上一页
    back() {
      this.$router.push('/money');
    },

    returnFloat(val) {
      var value = Math.round(parseFloat(val) * 100) / 100;
      var s = value.toString().split('.');
      if (s.length == 1) {
        value = value.toString() + '.00';

        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + '0';
        }
        return value;
      }
    },
    payChannel() {
      this.$api.payChannel().then((e) => {
        if (e.code === 0) {
          if (e.data.pay_channel == 2) {
            this.but(2);
          } else {
            this.but(1);
          }
        } else {
          this.but(1);
        }
      });
    },

    inputMony(e) {
      let res = e.target.value.match(/^\d{0,8}(\.?\d{0,2})/g)[0] || '';
      if (res > 500) {
        res = 500;
      }
      this.$nextTick(() => {
        this.mony = res;
      });
    },
    // 确认充值
    but(num) {
      if (this.checked == true) {
        this.pay_channel = 'alipay_wap';
      } else {
        // return this.$toast.fail('请选择充值方式');
        return;
      }

      if (!this.mony) {
        // this.$toast.fail('请输入充值金额');
        return;
      }
      // if(this.mony != /^\d*(\.\d{1,20})?$/){
      //   this.$toast.fail("请输入正确的金额")
      //   return;
      // }
      // if (this.mony == 0) {
      //   this.$toast.fail('充值金额不能为零');
      //   return;
      // }

      // 最少充值100不包括100元
      if (this.mony < 0.01) {
        this.$toast.fail('单笔充值金额不能少于0.01元');
        return;
      }
      //最大充值两万，不包括两万
      if (this.mony > 20000) {
        this.$toast.fail('单笔充值金额不能超过两万元');
        return;
      }

      let params = {
        token: localStorage.getItem('token'),
        pay_channel: this.pay_channel, //  充值方 式
        pay_amt: this.returnFloat(this.mony), // 充值金额
        pay_type: 'h5',
      };
      this.$api.recharge(params).then((res) => {
        if (num == 1) {
          if (res.status == 'succeeded') {
            this.orderNo = res.order_no;
            if (isSafari()) {
              window.location.href = res.expend.pay_info;
            } else {
              this.$router.push({
                path: '/Pay?type=1',
                query: {
                  payUrl: res.expend.pay_info,
                  orderNo: res.order_no,
                },
              });
            }
          } else {
            if (res.error_msg === '商户状态异常，请联系销售处理') {
              this.$toast('网络不佳, 请重试');
            } else {
              this.$toast('网络不佳, 请重试');
            }
          }
        } else {
          if (res.code == 0) {
            this.orderNo = res.data.order_no;
            this.payInfo = res.data.pay_info;
            this.isShowPay = true;
            localStorage.setItem('paying', this.orderNo);
            this.$nextTick(() => {
              document.getElementById('alipaysubmit').submit();
            });
          } else {
            return this.$toast('网络不佳, 请重试');
            // return this.$toast(res.msg);
          }
        }
      });

      // 支付宝充值
    },
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.cb);
  },
  mounted() {
    this.mony = this.monyList.list[0];
    if (isSafari()) {
      document.addEventListener('visibilitychange', this.cb);
    }
    // const paying = localStorage.getItem('paying');
    // console.log(paying);
    // if (paying && paying != 'undefined') {
    //   localStorage.removeItem('paying');
    //   const query = {
    //     token: localStorage.getItem('token'),
    //     order_no: paying,
    //   };
    //   this.$api.loop(query).then((e) => {
    //     if (e.code != 100000) return this.$toast(e.msg);
    //     this.info = JSON.stringify(e.data);
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 1000);
    //     if (e.data.status === 2) {
    //       this.$router.back();
    //       return this.$toast('支付成功');
    //     } else {
    //       return this.$toast('支付失败');
    //     }
    //   });
    // }
  },
};
</script>
<style lang="less" scoped>
input.num::-webkit-input-placeholder {
  font-size: 30px;
}

input.num:-moz-placeholder {
  font-size: 30px;
}

input.num::-moz-placeholder {
  font-size: 30px;
}
input.num:-ms-input-placeholder {
  font-size: 30px;
}
.monyList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .monyBox {
    width: 33.3%;
    padding: 10px 10px;
    box-sizing: border-box;
    .mony {
      border: 1px solid #0754d3;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      padding: 10px 0;
      align-items: center;
      color: #0754d3;
      font-size: 14px;
    }
    &.active {
      .mony {
        background: #0754d3;
        color: #fff;
      }
    }
    &:last-child {
      width: 66.6%;
      box-sizing: border-box;
    }
  }
}
.shuoming {
  font-size: 12px;
  color: #666666;
  padding-left: 20px;
}
.but {
  width: 330px;
  height: 42px;
  background-color: #eeeeee;
  text-align: center;
  line-height: 42px;
  color: #666666;
  margin: 0 auto;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 50px;
  &.active {
    background: #0754d3;
    color: #eee;
  }
}
.mon_text {
  font-size: 14px;
  line-height: 50px;
  margin-left: 20px;
}
.mon {
  margin: 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  .symbol {
    font-size: 32px;
  }
}
.num {
  width: 300px;
  border: 0;
  font-size: 35px;
}
.showdow {
  background-color: #f8f8f8;
  height: 5px;
}
.top_icon {
  display: flex;
  margin-left: 21px;
  align-items: center;
  justify-content: space-between;
  margin-right: 28px;
  height: 64px;
  // border-bottom: 1px #f3f4f5 solid;
  .way {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
    }
    .way_text {
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
.top_up {
  font-size: 14px;
  padding-left: 27px;
}
.topHeader {
  margin-bottom: 21px;
}
</style>
